export default [
    {
        path: '/settings/invoice-translations',
        name: 'pageInvoiceTranslationsList',
        meta: { title: 'invoiceTranslations.listTitle', roles: ['SETTING_INVOICE_TRANSLATION_VIEW'] },
        component: () => import('@/pages/settings/invoice-translations'),
    },
    {
        path: '/settings/invoice-translations/create',
        name: 'pageInvoiceTranslationsCreate',
        meta: { title: 'invoiceTranslations.createFormTitle', roles: ['SETTING_INVOICE_TRANSLATION_VIEW'] },
        component: () => import('@/pages/settings/invoice-translations/create'),
    },
    {
        path: '/settings/invoice-translations/:id/edit',
        name: 'pageInvoiceTranslationsEdit',
        meta: { title: 'invoiceTranslations.editFormTitle', roles: ['SETTING_INVOICE_TRANSLATION_VIEW'] },
        component: () => import('@/pages/settings/invoice-translations/id/edit'),
    },
]
