import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OrderWebAnalytica/helpers'
import orderRepository from '@/repository/admin/orderRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import i18n from '@/plugins/vue-i18n'
import { formatDate } from '@/helpers/date'
import { DATE_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.WEB_ANALYTICA_FILTERS) || { ...defaultFilters },
  orderAnalyticaList: [],
  totalAmount: 0,
  tableOptions: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  orderAnalyticaList: state => state.orderAnalyticaList,
  totalAmount: state => state.totalAmount,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOrderAnalyticaList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.WEB_ANALYTICA_FILTERS, filters)
    context.commit('setLoadingData', true)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilter = omitEmptyDeepFilters(filters.custom_filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
      custom_filter: appliedCustomFilter,
    })
    const { data } = await orderRepository.analytica(preparedFilters)

    context.commit('setOrderAnalyticaList', { data, filters })
    context.commit('setLoadingData', false)

    return {
      orderAnalyticaList: data.items,
      totalAmount: data.total_amount,
      pagination: data.pagination,
    }
  },
  async exportOrderAnalyticaList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilter = omitEmptyDeepFilters(filters.custom_filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
      custom_filter: appliedCustomFilter,
    })
    const response = await orderRepository.analyticaExport(
      { ...preparedFilters },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('webAnalytica.title')} ${
      formatDate(appliedCustomFilter.date_from, DATE_FORMAT)}-${formatDate(appliedCustomFilter.date_to, DATE_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async loadOrderAnalytica(context, id) {
    const { data } = await orderRepository.get(id)
    return data
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.WEB_ANALYTICA_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.WEB_ANALYTICA_FILTERS, updatedFilters)
  },
}

const mutations = {
  setOrderAnalyticaList(state, { data, filters }) {
    state.orderAnalyticaList = [...data.items]
    state.totalAmount = data.total_amount
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
