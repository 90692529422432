export default [
    {
        path: '/settings/currency-rate',
        name: 'pageCurrencyRateHistory',
        meta: { title: 'currencyRate.listTitle' },
        component: () => import('@/pages/settings/currency-rate'),
    },
    {
        path: '/settings/currency-rate/create',
        name: 'pageCurrencyRateHistoryCreate',
        meta: { title: 'currencyRate.historyCreate' },
        component: () => import('@/pages/settings/currency-rate/create'),
    },
    {
        path: '/settings/currency-rate/:id/edit',
        name: 'pageCurrencyRateHistoryEdit',
        meta: { title: 'currencyRate.historyUpdate' },
        component: () => import('@/pages/settings/currency-rate/id/edit'),
    },
]