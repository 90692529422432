export default [
  {
    path: '/settings/country',
    name: 'pageCountryList',
    meta: { title: 'page.pageSettingsCountry', roles: ['SETTING_COUNTRY_VIEW'] },
    component: () => import('@/pages/settings/country'),
  },
  {
    path: '/settings/country/create',
    name: 'pageCountryCreate',
    meta: { title: 'country.create', roles: ['SETTING_COUNTRY_VIEW'] },
    component: () => import('@/pages/settings/country/create'),
  },
  {
    path: '/settings/country/:id/edit',
    name: 'pageCountryEdit',
    meta: { title: 'country.editCountry', roles: ['SETTING_COUNTRY_VIEW'] },
    component: () => import('@/pages/settings/country/id/edit'),
  },
]
