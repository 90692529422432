import callCenterPriceRepository from '@/repository/admin/callCenterPriceRepository'

const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'

function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).sort((a, b) => {
      if (a === 'price' && b === 'currency') return -1;
      if (a === 'currency' && b === 'price') return 1;
      return 0;
    }).map(key => {
      return {
        text: i18n.t(`callCenterPrice.field.${key}`),
        sortable: false,
        value: key,
      }
    })
    : []
}

const state = {
  ccPriceList: [],
  ccPriceHeaders: [],
}

const getters = {
  ccPriceList: state => state.ccPriceList,
  ccPriceHeaders: state => state.ccPriceHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await callCenterPriceRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон стоимости КЦ.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await callCenterPriceRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await callCenterPriceRepository.preview(formData, config)
    data.forEach(item => {
      item.offer = item.offer?.name || item.offer
    })
    const headers = renderHeaders(data)

    context.commit('setCcPricePreviewHeaders', headers)
    context.commit('setCcPricePreviewList', data)

    return {
      ccPriceList: data,
      ccPriceHeaders: headers,
    }
  },
}

const mutations = {
  setCcPricePreviewList(state, data) {
    state.ccPriceList = data
  },
  setCcPricePreviewHeaders(state, data) {
    state.ccPriceHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
