import SuperRepository from '../superRepository'

export default new (class WriteOffHistoryRepository extends SuperRepository {
  baseUri() {
    return 'warehouse_write_off_history'
  }
  export = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/export`, data, params)
  exportReport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/product_export`, data, params)
})()
