<template>
  <form>
    <sticky-header class="d-flex align-center justify-space-between">
      <slot name="header" :on-submit="onSubmit">
        <v-row align="center">
          <v-col cols="12" md="" class="pb-0 pb-md-3">
            <div class="black--text text--darken-6 text-h1 pt-2 pt-sm-5 pb-5">
              {{ title }}
            </div>
          </v-col>
          <v-col cols="12" md="auto" align="right" class="form-actions">
            <slot name="actions">
              <ui-button v-if="!hideBack" variant="white" w120sm100 class="mx-md-3" @click="$router.push({ name: pathName })">
                {{ $t('base.back') }}
              </ui-button>
              <ui-button v-if="checkPermission" w120sm100 :disabled="isLoading" @click="onSubmit">
                {{ $t('base.save') }}
              </ui-button>
            </slot>
          </v-col>
        </v-row>
      </slot>
    </sticky-header>
    <slot></slot>
  </form>
</template>

<script>
import StickyHeader from '@/components/ui/StickyHeader'
import { hasPermission } from '@/helpers/permission'

export default {
  name: 'ui-form-entity',
  components: { StickyHeader },
  props: {
    title: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    permission: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkPermission() {
      return this.permission.length ? this.hasPermission(this.permission) : true
    },
  },
  methods: {
    hasPermission,
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss">
.form-actions {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}
</style>
