import postalCodesRepository from '@/repository/admin/postalCodesRepository'
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map(key => {
        return {
          text: i18n.t(`postalCodes.table.${key}`),
          sortable: false,
          value: key,
        }
      })
    : []
}

const state = {
  postalCodesPreviewList: [],
  postalCodesPreviewHeaders: [],
}

const getters = {
  postalCodesPreviewList: state => state.postalCodesPreviewList,
  postalCodesPreviewHeaders: state => state.postalCodesPreviewHeaders,
}

const actions = {
  async importFile(context, payload) {
    const { formData, config } = payload
    return await postalCodesRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await postalCodesRepository.preview(formData, config)
    data.forEach(item => {
      item.deliveryService = item.deliveryService?.name || item.deliveryService
      item.warehouse = item.warehouse?.name || item.warehouse
      item.region = item.region?.name || item.region
    })
    const headers = renderHeaders(data)
    const preparedHeaders= headers.filter(item => item.value !== 'country')

    context.commit('setPostalCodesPreviewHeaders', preparedHeaders)
    context.commit('setPostalCodesPreviewList', data)

    return {
      postalCodesPreviewList: data,
      postalCodesPreviewHeaders: preparedHeaders,
    }
  },
}

const mutations = {
  setPostalCodesPreviewList(state, data) {
    state.postalCodesPreviewList = data
  },
  setPostalCodesPreviewHeaders(state, data) {
    state.postalCodesPreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
