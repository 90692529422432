import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OperatorStatistics/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.OPERATOR_STATISTIC_FILTERS) || { ...defaultFilters },
  operatorStatisticList: [],
  operatorStatisticTotal: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  operatorStatisticList: state => state.operatorStatisticList,
  operatorStatisticTotal: state => state.operatorStatisticTotal,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOperatorStatisticList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_STATISTIC_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.operatorStatistics(preparedFilters.filter)
    context.commit('setOperatorStatisticList', {
      data,
      pagination: mockPagination(data.rows, filters),
      filters,
    })

    return { operatorStatisticList: data.rows, operatorStatisticTotal: data.total }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OPERATOR_STATISTIC_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OPERATOR_STATISTIC_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_STATISTIC_FILTERS, filters)
    context.commit('setOperatorStatisticList', {
      data: {
        rows: context.getters.operatorStatisticList,
        total: context.getters.operatorStatisticTotal,
      },
      pagination: mockPagination(context.getters.operatorStatisticList, filters),
      filters,
    })
  },
}

const mutations = {
  setOperatorStatisticList(state, { data, filters, pagination }) {
    state.operatorStatisticList = data.rows
    state.operatorStatisticTotal = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
