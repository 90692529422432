export default [
  {
    path: '/logistics/export-reports',
    name: 'exportReportsLogistics',
    meta: {
      title: 'page.exportReports',
      roles: [
        'LOGISTIC_REPORT_EXPORT_ORDER_FOR_SELECTED_PERIOD',
        'LOGISTIC_REPORT_EXPORT_ORDER',
        'LOGISTIC_REPORT_CSV_ORDER',
        'LOGISTIC_REPORT_PRODUCT_LIST',
        'LOGISTIC_REPORT_EXPORT_WRITE_OFF_PRODUCTS',
      ],
    },
    component: () => import('@/pages/settings/DashboardReports'),
  },
]
