import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Country/helpers'
import countryRepository from '@/repository/common/countryRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.COUNTRY_FILTERS) || { ...defaultFilters },
  countryList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  countryList: state => state.countryList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCountryList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COUNTRY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await countryRepository.list(preparedFilters)

    context.commit('setCountryList', { data, filters })

    return { countryList: data.items, pagination: data.pagination }
  },
  async searchCountryList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await countryRepository.list(preparedFilters)

    return { countryList: data.items, pagination: data.pagination }
  },
  async loadCountry(context, id) {
    const { data } = await countryRepository.get(id)
    return data
  },
  createCountry(context, payload) {
    return countryRepository.post(payload)
  },
  updateCountry(context, payload) {
    const { id, ...data } = payload
    return countryRepository.put(id, data)
  },
  deleteCountry(context, payload) {
    return countryRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.COUNTRY_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.COUNTRY_FILTERS, updatedFilters)
  },
}

const mutations = {
  setCountryList(state, { data, filters }) {
    state.countryList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
