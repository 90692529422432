<template>
  <ui-select
    ref="select"
    v-model="selectedOption"
    :options="filteredOptions"
    :limit="limit"
    :internal-search="false"
    :loading="loading"
    v-bind="bindingsSelect"
    preserve-search
    lazy-loading
    :no-more-options="noMoreOptions"
    :wider="wider"
    :wider-right="widerRight"
    :hide-options-menu="hideOptionsMenu"
    :hide-select-all-btn="hideSelectAllBtn"
    :query="searchFilters.query"
    :last-load-page="searchFilters.page"
    :show-counter="showCounter"
    :counter-value="counterValue"
    :single-line="singleLine"
    v-on="otherListeners"
    @search-change="onSearchQueryChange"
    @load-more="onLoadMore"
    @input="onInput"
    @close="$emit('close')"
    @onSubmit="$emit('onSubmitBtn')"
    @onCancel="$emit('onCancelBtn')"
    @focused="onFocus(); $emit('focus')"
    @get-all-options-selected="onLoadAllItems"
    @update:counter="$emit('update:counter', $event)"
  >
    <template #label>
      <slot name="label">
        {{ labelName }}
      </slot>
    </template>

    <template v-if="$scopedSlots.option" #option="{ option }">
      <slot name="option" :option="option" />
    </template>

    <template v-if="$scopedSlots.singleLabel" #singleLabel="{ option }">
      <slot name="singleLabel" :option="option" />
    </template>

    <template v-if="$scopedSlots.iconLeft" #iconLeft>
      <slot name="iconLeft" />
    </template>

    <template v-if="$scopedSlots.iconRight" #iconRight>
      <slot name="iconRight" />
    </template>

    <template #noOptions>
      {{ $t('base.startTypingText') }}
    </template>

    <template v-if="$scopedSlots.noResult" #noResult>
      <slot name="noResult" />
    </template>
  </ui-select>
</template>
<script>
import {debounce, keys, omit} from 'lodash'
const OPTIONS_LIST_CLASS = 'multiselect__content-wrapper'

export default {
  name: 'LazyLoadingSelect',
  props: {
    value: {
      type: [Object, String, Array, Number],
      default: null,
    },
    labelName: {
      type: String,
      default: '',
    },
    searchFunction: {
      type: Function,
      default: () => [],
    },
    searchParams: {
      type: Object,
      default: () => {},
    },
    optionsFilter: {
      type: Function,
      default: options => options,
    },
    chooseFirstOption: {
      type: [Boolean, Number],
      default: false
    },
    hideOptionsMenu: {
      type: Boolean,
      default: false
    },
    fetchOnFocus: {
      type: Boolean,
      default: false
    },
    refetchOnFocus: {
      type: Boolean,
      default: false
    },
    wider: {
      type: Boolean,
      default: false
    },
    widerRight: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 2
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    counterValue: {
      type: Number,
      default: 1
    },
    hideSelectAllBtn: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      options: [],
      loading: false,
      noMoreOptions: false,
      searchFilters: {
        page: 1,
        limit: 10,
        query: '',
        last: 1,
      },
      params: null
    }
  },
  computed: {
    bindingsSelect() {
      return {
        ...omit(this.$attrs, ['value', 'searchFunction', 'searchParams']),
      }
    },
    otherListeners() {
      return omit(this.$listeners, ['search-change', 'load-more', 'input'])
    },
    filteredOptions() {
      return this.optionsFilter(this.options)
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedOption = value
      },
      immediate: true,
    },
  },
  mounted() {
    this.params = keys(this.searchParams).length ? this.searchParams : null
    if(!this.fetchOnFocus && !this.refetchOnFocus) this.onSearchChange('')
  },
  methods: {
    onInput() {
      this.$emit('input', this.selectedOption)
    },
    onFocus() {
      if(!this.filteredOptions?.length && this.fetchOnFocus) this.onSearchChange('')
      if(this.refetchOnFocus) this.onSearchChange('')
    },
    onSearchQueryChange: debounce(function (query) {
      this.onSearchChange(query)
    }, 300),
    async onSearchChange(query) {
      this.loading = true
      try {
        this.searchFilters.page = 1
        this.searchFilters.query = query
        this.noMoreOptions = false
        const { limit, page } = this.searchFilters
        const result = await this.searchFunction({ query, limit, page }, this.params)
        this.options = result?.options
        const pagination = result?.pagination

        const listEl = this.$refs.select?.$el.getElementsByClassName(OPTIONS_LIST_CLASS)
        if (listEl?.length) {
          listEl[0].scrollTo(0, 0)
        }

        this.searchFilters.last = pagination?.maxPageNumber || 1
        this.noMoreOptions = page >= this.searchFilters.last
        if (this.chooseFirstOption && (!this.selectedOption || typeof this.selectedOption !== 'object')) {
          this.selectedOption = this.options?.length ? this.options[0] : ''
          this.onInput()
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async onLoadMore() {
      this.loading = true
      try {
        this.searchFilters.page += 1
        const { query, page, limit } = this.searchFilters

        const { options } = await this.searchFunction({ query, page, limit }, this.params)
        this.options = [...this.options, ...options]
        this.noMoreOptions = page >= this.searchFilters.last
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async onLoadAllItems(){
      this.loading = true
      try {
        const { query } = this.searchFilters
        const { options } = await this.searchFunction({ query, page: 1, limit: -1 }, this.params)
        let preparedOptions = options.filter(item => !this.selectedOption.some(el => el.id === item.id))
        this.selectedOption = [
          ...this.selectedOption?.length ? this.selectedOption : [],
          ...preparedOptions?.length ? preparedOptions : []
        ]
        this.onInput()
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    resetSearch(filter = null) {
      this.searchFilters = {
        page: 1,
        limit: 10,
        query: '',
        last: 1,
      }
      this.params = filter
      this.onSearchChange('')
    },
  },
}
</script>
