import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import { CURRENCIES } from '@/constans/currencies'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'day',
    secondGroup: 'offer',
    filterDateBy: 'created_at',
    currency: CURRENCIES.EUR,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModels: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    cities: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operators: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryType: '',
    webs: [],
    landings: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouses: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    questionnaires: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    gender: '',
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedCallCentreFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.offers.filter.id.value = joinBySymbol(filter.offers)
  filters.offerGroups.filter.id.value = joinBySymbol(filter.offerGroups)
  filters.tags.filter.id.value = joinBySymbol(filter.orderTags)
  filters.niches.filter.id.value = joinBySymbol(filter.offerNiches)
  filters.salesModels.filter.id.value = joinBySymbol(filter.offerSalesModels)
  filters.deliveryServices.filter.id.value = joinBySymbol(filter.deliveryServices)
  filters.countries.filter.id.value = joinBySymbol(filter.countries)
  filters.regions.filter.id.value = joinBySymbol(filter.regions)
  filters.cities.filter.id.value = joinBySymbol(filter.cities)
  filters.userGroups.filter.id.value = joinBySymbol(filter.userGroups)
  filters.operators.filter.id.value = joinBySymbol(filter.operators)
  filters.landings.filter.id.value = joinBySymbol(filter.landings)
  filters.salesTypes.filter.id.value = joinBySymbol(filter.salesTypes)
  filters.questionnaires.filter.id.value = joinBySymbol(filter.questionnaires)
  filters.deliveryType = filter.deliveryTypes
  filters.webs = Array.isArray(filter.orderWebs)
    ? filter.orderWebs.map(item => item.id || item)
    : filter.orderWebs
  filters.gender = filter.gender
  filters.dateFrom = joinBySymbol(filter.dateFrom)
  filters.dateTo = joinBySymbol(filter.dateTo)
  filters.filterDateBy = joinBySymbol(filter.filterDateBy)

  return filters
}
