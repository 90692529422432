<template>
  <ui-card class="px-3 py-3 ui-table-actions" elevation="24" :flat="false" color="white">
    <div class="d-flex align-center flex-column flex-md-row">
      <div class="d-flex align-center">
        <ui-button theme="flat" round is-icon @click="onClose">
          <v-icon> mdi-close </v-icon>
        </ui-button>

        <div class="primary--text text-body-1 font-weight-bold ml-2 mr-1">
          {{ selectedCount }}
        </div>

        <div class="text-body-1 mr-md-16">
          {{ selectedText }}
        </div>
      </div>

      <div class="d-flex">
        <slot />
      </div>
    </div>
  </ui-card>
</template>
<script>
export default {
  name: 'UITableActions',
  props: {
    selectedCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    selectedText() {
      return this.$t('base.selected').toLowerCase()
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.ui-table-actions {
  position: fixed;
  bottom: 63px;
  left: calc(50% + 122px);
  transform: translate3d(-50%, 0, 0);
  max-width: calc(100% - 264px);
  z-index: 99;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    max-width: 100vw;
  }
}
</style>
