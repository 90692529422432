export default [
    {
        path: '/settings/invoice-constructor',
        name: 'pageInvoiceConstructorList',
        meta: { title: 'invoiceConstructor.listTitle', roles: ['SETTING_INVOICE_CONSTRUCT_VIEW'] },
        component: () => import('@/pages/settings/invoice-constructor'),
    },
    {
        path: '/settings/invoice-constructor/create',
        name: 'pageInvoiceConstructorCreate',
        meta: { title: 'invoiceConstructor.createFormTitle', roles: ['SETTING_INVOICE_CONSTRUCT_VIEW'] },
        component: () => import('@/pages/settings/invoice-constructor/create'),
    },
    {
        path: '/settings/invoice-constructor/:id/edit',
        name: 'pageInvoiceConstructorEdit',
        meta: { title: 'invoiceConstructor.editFormTitle', roles: ['SETTING_INVOICE_CONSTRUCT_VIEW'] },
        component: () => import('@/pages/settings/invoice-constructor/id/edit'),
    },
]
