import orderImportRepository from '@/repository/admin/orderImportRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map(key => {
        return {
          text: i18n.t(`orderImport.field.${key}`),
          sortable: false,
          value: key,
        }
      })
    : []
}

const state = {
  orderPreviewList: [],
  orderPreviewHeaders: [],
}

const getters = {
  orderPreviewList: state => state.orderPreviewList,
  orderPreviewHeaders: state => state.orderPreviewHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await orderImportRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон импорта заявок.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await orderImportRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await orderImportRepository.preview(formData, config)
    data.forEach(item => {
      item.city = item.city?.name || item.city
      item.offer = item.offer?.name || item.offer
    })
    const headers = renderHeaders(data)

    context.commit('setOrderPreviewHeaders', headers)
    context.commit('setOrderPreviewList', data)

    return {
      orderPreviewList: data,
      orderPreviewHeaders: headers,
    }
  },
}

const mutations = {
  setOrderPreviewList(state, data) {
    state.orderPreviewList = data
  },
  setOrderPreviewHeaders(state, data) {
    state.orderPreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
