<template>
  <modal-wrapper
    max-width="500px"
    @close="onClose">
    <ui-card class="pt-6 pb-0 px-0">
      <v-card-title class="d-flex justify-space-between align-center text-h2 pa-0">
        {{ $t('error.systemUpdate') }}
        <ui-btn
          v-if="isCloseIcon"
          icon
          link
          plain
          min-width="24"
          max-width="24"
          min-height="24"
          max-height="24"
          @click="onClose"
        >
          <v-icon color="info" size="24">mdi-window-close</v-icon>
        </ui-btn>
      </v-card-title>
      <v-card-text class="body-1 black--text text--darken-3 px-0 pt-6 pb-0">
        <span>{{ $t('error.systemUpdateDesc') }}</span>
      </v-card-text>
      <v-card-actions class="pa-0 mt-7">
        <v-spacer></v-spacer>
        <ui-button
          v-if="!btnCancelOption.hidden"
          w120
          :theme="btnCancelOption.theme"
          @click="onClose"
        >
          {{ btnCancelOption.title }}
        </ui-button>
        <ui-button
          v-if="!btnSubmitOption.hidden"
          w120
          :theme="btnSubmitOption.theme"
          @click="onSubmit"
        >
          {{ btnSubmitOption.title }}
        </ui-button>
      </v-card-actions>
    </ui-card>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/ui/modals/ModalWrapper";

export default {
  name: "ModalConfirmReload",
  components: {ModalWrapper},
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: '',
    },
    btnCancel: {
      type: Object,
      default: () => {
      }
    },
    btnSubmit: {
      type: Object,
      default: () => {
      }
    },
    isCloseIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultBtnCancel: {
        theme: 'outline',
        title: this.$t('base.close')
      },
      defaultBtnSubmit: {
        theme: 'default',
        title: this.$t('error.refreshPage')
      }
    }
  },
  computed: {
    btnCancelOption() {
      return {
        ...this.defaultBtnCancel,
        ...this.btnCancel
      }
    },
    btnSubmitOption() {
      return {
        ...this.defaultBtnSubmit,
        ...this.btnSubmit
      }
    }
  },
  methods: {
    onClose() {
      localStorage.setItem('reload_modal_shown', 'true')
      this.$emit('close')
    },
    onSubmit() {
      localStorage.setItem('reload_modal_shown', 'true')
      location.reload()
      this.$emit('close')
    }
  }
}
</script>
