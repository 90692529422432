import moment from 'moment/moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
        dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
        countries: null,
        userGroups: null,
        operators: null,
    },
    clientOptions: {
        filterPanelExpanded: false,
    },
}
