export default [
  {
    path: '/manager/export-reports',
    name: 'exportReportsManager',
    meta: {
      title: 'page.exportReports',
      roles: [
        'MNG_REPORT_EXPORT_ORDER',
        'MNG_REPORT_EXPORT_REPORT_COD'
      ],
    },
    component: () => import('@/pages/settings/DashboardReports'),
  },
]
