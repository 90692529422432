import moment from 'moment'
import { DATE_FORM_FORMAT } from '@/constans/date'
import { CURRENCIES } from '@/constans/currencies'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  sortBy: ['title'],
  sortDesc: [true],
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'sales_model',
    secondGroup: 'offer',
    currency: CURRENCIES.EUR,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModels: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operators: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    cities: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryTypes: [],
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    organizations: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    productTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    warehouses: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    gender: null,
    ages: [],
    filterDateBy: 'created_at',
    attributes: null,
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}
