import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OperatorOrderTimeReport/helpers'
import TableOptions from '@/models/TableOptions'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { OrderReportRepository } from '@/repository/admin'
import moment from 'moment'
import { TIME_FORMAT } from '@/constans/date'

const state = {
  list: [],
  data: {},
  headers: [],
  filters: StorageService.get(storageKeys.OPERATOR_ORDER_TIME_REPORT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  data: state => state.data,
  headers: state => state.headers,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_ORDER_TIME_REPORT_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    // const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...appliedFilters,
    })

    const { data } = await OrderReportRepository.operatorOrderTimeReport(preparedFilters)
    data.operators = Object.entries(data.operators).map(([key, op]) => {
      return {
        value: key,
        text: `${op.name} ${op.last_name}`,
        sortable: false,
      }
    })
    data.orders = Object.entries(data.orders).map(([order, values]) => {
      Object.keys(values).forEach((key) => {
        values[key] = moment.utc(values[key]*1000).format(TIME_FORMAT);
      })
      return {
        id: order,
        ...values
      }
    })
    context.commit('setList', {
      data,
      pagination: mockPagination(Object.values(data.orders), filters),
      filters
    })

    return { products: data.items, pagination: data.pagination }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OPERATOR_ORDER_TIME_REPORT_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OPERATOR_ORDER_TIME_REPORT_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_ORDER_TIME_REPORT_FILTERS, filters)
    context.commit('setList', {
      data: context.getters.data,
      pagination: mockPagination(Object.values(context.getters.data.orders), filters),
      filters,
    })
  },
}

const mutations = {
  setList(state, { data, filters, pagination }) {
    state.list = [...data.orders]
    state.data = { ...data }
    state.headers = [{
      text: 'ID',
      value: 'id',
    }, ...data.operators]
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
