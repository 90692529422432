import SuperRepository from '../superRepository'

export default new (class workTimeRepo extends SuperRepository {
  baseUri() {
    return 'work_time_log'
  }

  report = async params => this.httpClient().post(`${this.baseUri()}/reports`, params)
  break = async params =>
    this.httpClient().get(`${this.baseUri()}/personal_break_time_left`, params)
  workTimeExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/reports`, data, params)
})()
