import LogisticPriceRepository from '@/repository/admin/logisticPriceRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  if(Array.isArray(data) && data.length){
    let keys = Object.keys(data[0]);
    const backPriceIndex = keys.indexOf('backPrice');
    const currencyIndex = keys.indexOf('currency');
    if (currencyIndex !== -1) {
      const currencyKey = keys.splice(currencyIndex, 1)[0];
      if (backPriceIndex !== -1) {
        keys.splice(backPriceIndex, 0, currencyKey);
      }
    }
    return keys.map(key => {
      return {
        text: i18n.t(`logisticPrice.field.${key}`),
        sortable: false,
        value: key,
      }
    })
  } else {
    return []
  }
}

const state = {
  logisticPriceList: [],
  logisticPriceHeaders: [],
}

const getters = {
  logisticPriceList: state => state.logisticPriceList,
  logisticPriceHeaders: state => state.logisticPriceHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await LogisticPriceRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон стоимости логистики.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await LogisticPriceRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await LogisticPriceRepository.preview(formData, config)

    const headers = renderHeaders(data)

    context.commit('setLogisticPricePreviewHeaders', headers)
    context.commit('setLogisticPricePreviewList', data)

    return {
      logisticPriceList: data,
      logisticPriceHeaders: headers,
    }
  },
}

const mutations = {
  setLogisticPricePreviewList(state, data) {
    state.logisticPriceList = data
  },
  setLogisticPricePreviewHeaders(state, data) {
    state.logisticPriceHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
