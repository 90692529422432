import moment from 'moment/moment'
import { DATE_FORM_FORMAT } from '@/constans/date'
import { CURRENCIES } from '@/constans/currencies'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    offer: {
      id: ''
    },
  },
  custom_filter: {
    date_from: moment().startOf('month').format(DATE_FORM_FORMAT),
    date_to: moment().endOf('month').format(DATE_FORM_FORMAT),
    countries: [],
    webId: [],
  },
  currency_id: CURRENCIES.EUR,
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
