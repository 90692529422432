<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12">
        <div class="d-flex align-start align-sm-center justify-space-between flex-column flex-sm-row entity-wrapper px-md-2">
          <div class="black--text text--darken-6 text-h1 pt-2 pt-sm-5 pb-5 custom-title text-wrap">
            <span
              class="cursor-pointer"
              v-tooltip="{
                content: title,
                placement: 'top-end',
              }"
            >
              {{ title }}</span>
          </div>
          <div v-if="isShowActions" class="d-flex justify-center justify-sm-end flex-column flex-sm-row entity-btns">
            <slot name="actions">
              <slot name="leftActions" />

              <div class="ml-md-3 mt-3 mt-sm-0 list-entity-btns" :class="{'no-create': hideDefaultCreate || hideFilter}">
                <ui-button
                  v-if="!hideFilter"
                  :class="{ 'filter-open': isFilterActive }"
                  variant="white"
                  class="ml-0 mx-0 mb-0 mb-md-0 list-entity-btn filter-btn gray800--text"
                  w120sm100
                  @click="onToggleFilter"
                >
                  <template #iconLeft>
                    <v-icon :color="defineIconColor">mdi-filter-variant</v-icon>
                  </template>
                  {{ $t('base.filters') }}
                </ui-button>

                <slot name="defaultCreate">
                  <ui-button
                    v-if="!hideDefaultCreate"
                    v-permission="btnCreate.permission"
                    class="list-entity-btn blue500"
                    w120sm100
                    @click="$router.push({ name: btnCreate.path })"
                  >
                    <template #iconLeft>
                      <v-icon>{{ btnCreate.icon || 'mdi-plus' }}</v-icon>
                    </template>
                    {{ btnCreate.title }}
                  </ui-button>
                </slot>
              </div>

              <slot name="rightActions" />
            </slot>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="px-md-4">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ui-list-entity',
  props: {
    title: {
      type: String,
      default: '',
    },
    isShowActions: {
      type: Boolean,
      default: true,
    },
    isFilterActive: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideDefaultCreate: {
      type: Boolean,
      default: false,
    },
    btnCreate: {
      type: Object,
      default: () => {
        return {
          path: '',
          title: '',
          icon: false,
          permission: [],
        }
      },
    },
  },
  computed: {
    defineIconColor() {
      return !this.isFilterActive ? 'black darken-3' : 'primary base'
    },
  },
  methods: {
    onToggleFilter() {
      this.$emit('toggleFilter')
    },
  },
}
</script>

<style lang="scss">
.custom-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-btn.filter-btn.filter-open {
  border: 1px solid #5757e2 !important;
  color: #5757e2 !important;
}
.list-entity-btns {
  display: flex;
  gap: 12px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &:not(.no-create) {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
.list-entity-btn {
  width: calc(50% - 6px)!important;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 120px;
  }
  &.filter-btn {
    background-color: white;
  }
}
.entity-btns {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
}
</style>
