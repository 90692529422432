import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    mainCountry: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    cities: {
      warehouse: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      city: {
        id: {
          value: '',
          operator: 'in',
        },
        district: {
          region: {
            id: {
              value: '',
              operator: 'in',
            },
          },
        },
      },
    },
    active: 1,
  },
  deliveryService: {
    id: '',
    type: '',
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDeliveryServiceFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.mainCountry.id.value = joinBySymbol(filter.mainCountry)
  filters.cities.warehouse.id.value = joinBySymbol(filter.warehouses)
  filters.cities.city.id.value = joinBySymbol(filter.cities)
  filters.cities.city.district.region.id.value = joinBySymbol(filter.regions)
  filters.active = filter.active

  return filters
}
