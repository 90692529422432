<template>
  <v-switch
      v-model="getValue"
      inset
      dense
      v-bind="$attrs"
      v-on="$listeners"
      class="ui-switch"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </v-switch>
</template>

<script>
export default {
  name: "ui-switch",
  props: {
    value: null,
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style lang="scss">
.ui-switch .v-input__control {
  padding-top: 8px;
}
</style>
