import i18n from '@/plugins/vue-i18n'
import deliveryWarehouseRepository from '@/repository/admin/deliveryWarehouseRepository'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map(key => {
        return {
          text: i18n.t(`deliveryWarehouse.table.${key}`),
          sortable: false,
          value: key,
        }
      })
    : []
}

const state = {
  deliveryWarehousePreviewList: [],
  deliveryWarehousePreviewHeaders: [],
}

const getters = {
  deliveryWarehousePreviewList: state => state.deliveryWarehousePreviewList,
  deliveryWarehousePreviewHeaders: state => state.deliveryWarehousePreviewHeaders,
}

const actions = {
  async importFile(context, payload) {
    const { formData, config } = payload
    return await deliveryWarehouseRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await deliveryWarehouseRepository.preview(formData, config)
    data.forEach(item => {
      item.warehouse = item.warehouse?.name || item.warehouse
      item.name = item.name?.name || item.name
    })
    const headers = renderHeaders(data)
    const preparedHeaders= headers.filter(item => item.value !== 'country')

    context.commit('setDeliveryWarehousePreviewHeaders', preparedHeaders)
    context.commit('setDeliveryWarehousePreviewList', data)

    return {
      deliveryWarehousePreviewList: data,
      deliveryWarehousePreviewHeaders: preparedHeaders,
    }
  },
}

const mutations = {
  setDeliveryWarehousePreviewList(state, data) {
    state.deliveryWarehousePreviewList = data
  },
  setDeliveryWarehousePreviewHeaders(state, data) {
    state.deliveryWarehousePreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
